* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: white;
}

html,
body {
  position: relative;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  background-color: white;
}

button {
  font-family: "Roboto", sans-serif;
}

header {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}

nav {
  width: 100%;
  border-radius: 0;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 25;
  padding: 0.8rem 2.5rem;
  background-color: transparent;
  position: fixed;
}
nav .links {
  margin: auto;
  display: flex;
  height: 50px;
  border-radius: 30px;
  list-style: none;
  background: transparent;
}
nav .links a {
  display: flex;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
  height: 100%;
  border-radius: 10px;
  text-decoration: none;
  background-color: #699e7e;
  margin-left: 8px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.404));
  font-size: 18px;
  font-weight: 400;
  transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
  white-space: nowrap;
}
nav .links a:hover {
  background-color: #ffad33;
  box-shadow: inset 0 0 0 #ffad33, 0 0 0.8em #ffad33;
}
nav .links .active {
  background-color: #ffad33;
}
nav .links-mobile {
  width: 260px;
  display: flex;
  justify-content: flex-end;
}
nav .links-mobile .hamburger {
  display: none;
}
nav a {
  display: flex;
}
nav a .logo {
  display: flex;
  width: 260px;
  transition: all 0.2s ease;
}
nav a .logo img {
  width: 260px;
  transition: all 0.2s ease;
  margin: auto 0;
}

.navbar-scroll {
  background-color: white;
  box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.navbar-scroll .logo {
  transition: all 0.2s ease;
}
.navbar-scroll .logo img {
  width: 160px;
  transition: all 0.2s ease;
}

.heading h2 {
  line-height: 36px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #383838;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr -webkit-max-content 1fr;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  max-width: 580px;
  align-items: center;
  border-radius: 10px;
  padding: 20px 20px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}
.heading h2:before {
  content: " ";
  display: block;
  border-bottom: 3px solid #ffad33;
  height: 1px;
  margin: 0 20px 0 0;
}
.heading h2:after {
  content: " ";
  display: block;
  border-top: 3px solid #ffad33;
  height: 1px;
  margin: 0 0 0 20px;
}

.heading2 h2 {
  line-height: 36px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #383838;
  letter-spacing: 0px;
  text-transform: uppercase;
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr -webkit-max-content 1fr;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  max-width: 410px;
  align-items: center;
  border-radius: 10px;
  padding: 20px 20px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}
.heading2 h2:before {
  content: " ";
  display: block;
  border-bottom: 3px solid #ffad33;
  height: 1px;
  margin: 0 20px 0 0;
}
.heading2 h2:after {
  content: " ";
  display: block;
  border-top: 3px solid #ffad33;
  height: 1px;
  margin: 0 0 0 20px;
}

.popup {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.662745098);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  margin-bottom: auto;
  margin-inline: auto;
  z-index: 100;
}
.popup .popup-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border: 5px solid #1a6136;
  background-color: rgba(0, 0, 0, 0.6980392157);
  border-radius: 10px;
  padding: 3%;
  margin: 3%;
}
.popup .popup-inner a h1 {
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.829);
  line-height: normal;
  margin-top: 3%;
  margin-bottom: 3%;
  font-size: 30px;
}
.popup .popup-inner a h2 {
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.829);
  font-size: 30px;
}
.popup .popup-inner img {
  display: flex;
  margin-inline: auto;
}
.popup .popup-inner button {
  cursor: pointer;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #699e7e;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  max-width: 300px;
}
.popup .popup-inner a {
  margin-top: auto;
  margin-bottom: auto;
}
.popup .popup-inner a img {
  max-width: 300px;
}

.bar {
  margin-top: 126px;
  background: #1a6136;
  padding: 20px 0;
  position: relative;
}
.bar .elem-8 {
  position: absolute;
  left: -42px;
  bottom: -12px;
}
.bar .elem-9 {
  position: absolute;
  right: 0;
  top: 32%;
}
.bar .container .row .row-text {
  display: flex;
  justify-content: center;
}
.bar .container .row .row-text h1 {
  font-size: 2.4rem;
  line-height: 36px;
  font-weight: 600;
  color: white;
}
.bar .container .row .row-text h1:after {
  display: inline-block;
  margin: 0 0 8px 20px;
  height: 3px;
  content: " ";
  text-shadow: none;
  background-color: #ffad33;
  width: 80px;
}
.bar .container .row .row-text h1:before {
  display: inline-block;
  margin: 0 20px 8px 0;
  height: 3px;
  content: " ";
  text-shadow: none;
  background-color: #ffad33;
  width: 80px;
}

.footer {
  background: #262729;
  color: #fff;
}
.footer .footer-container {
  max-width: 1400px;
  padding: 40px 20px 0 20px;
  margin: 0 auto;
}
.footer .footer-container .row1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
.footer .footer-container .row1 .item .item-container {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}
.footer .footer-container .row1 .item .item-container .item-list {
  list-style: none;
}
.footer .footer-container .row2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.footer .footer-container .row2 .row2-text {
  width: 100%;
}
.footer .footer-container .row2 .row2-text .copy_footer {
  font-size: 14px;
  font-weight: 300;
  margin-top: 38px;
  padding: 25px 0;
  border-top: 1px solid #ffad33;
  text-align: center;
}

.strona-glowna {
  margin: 0 2%;
  padding-top: 126px;
}
.strona-glowna .strona-glowna-content {
  max-width: 1700px;
  min-height: auto;
  background-color: #1a6136;
  padding: 80px 0;
  position: relative;
  margin: 0 auto;
  border-radius: 30px;
  box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
}
.strona-glowna .strona-glowna-content .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.strona-glowna .strona-glowna-content .container .col-1 {
  position: relative;
  padding: 0 15px;
  margin-top: auto;
  margin-bottom: auto;
}
.strona-glowna .strona-glowna-content .container .col-1 h1 {
  line-height: 50px;
  font-size: 35px;
  font-weight: 500;
  padding: 10px 20px 2px;
  color: white;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.329);
}
.strona-glowna .strona-glowna-content .container .col-1 h2 {
  line-height: 70px;
  font-size: 50px;
  font-weight: 700;
  padding: 10px 20px 2px;
  color: white;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.329);
}
.strona-glowna .strona-glowna-content .container .col-1 p {
  line-height: 20px;
  font-size: 20px;
  font-weight: 300;
  padding: 10px 20px;
  color: white;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.329);
}
.strona-glowna .strona-glowna-content .container .col-1 p:last-of-type {
  font-size: 14px;
  padding-bottom: 40px;
}
.strona-glowna .strona-glowna-content .container .col-1 .buttons {
  padding: 10px 20px 2px;
}
.strona-glowna .strona-glowna-content .container .col-1 .buttons a:last-child {
  margin-left: 20px;
}
.strona-glowna .strona-glowna-content .container .col-1 .buttons .btn {
  cursor: pointer;
  background: transparent;
  color: white;
  width: 148px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  border: 1px solid #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.707));
  position: relative;
  z-index: 22;
}
.strona-glowna .strona-glowna-content .container .col-1 .buttons :after {
  box-sizing: border-box;
  content: "";
  background: #ffad33;
  border-radius: 4px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: 0 50%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition-property: transform;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;
  position: absolute;
}
.strona-glowna .strona-glowna-content .container .col-1 .buttons :hover::after {
  transform: scaleX(1);
}
.strona-glowna .strona-glowna-content .container .col-1 .buttons :hover {
  border: #ffad33;
}
.strona-glowna .strona-glowna-content .col-2 {
  position: relative;
  margin-right: 60px;
  height: 100%;
}
.strona-glowna .strona-glowna-content .col-2 img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-radius: 25px;
  max-height: 500px;
  box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
}

.o-nas {
  position: relative;
  padding-top: 100px;
  margin: 20px auto;
  max-width: 1700px;
}
.o-nas .container-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 0 2%;
}
.o-nas .container-1 .col-1 {
  background-color: #1a6136;
  padding: 8px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
  border-radius: 25px;
}
.o-nas .container-1 .col-1 ::after {
  position: absolute;
  left: -4%;
  top: -7%;
  z-index: 1;
}
.o-nas .container-1 .col-1 .col-1-img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.o-nas .container-1 .col-1 .col-1-img img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-radius: 20px;
}
.o-nas .container-1 .col-2 .elem-4 {
  position: absolute;
  bottom: 6%;
  right: 3%;
  z-index: -1;
}
.o-nas .container-1 .col-2 .col-2-text h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
  color: #383838;
}
.o-nas .container-1 .col-2 .col-2-text p {
  color: #383838;
}
.o-nas .container-1 .col-2 .col-2-text h5 {
  padding-bottom: 5px;
  color: #ffad33;
  font-weight: 700;
  font-size: 1.25rem;
}
.o-nas .container-1 .col-2 .col-2-text2 h5 {
  padding-bottom: 5px;
  color: #383838;
  font-weight: 700;
  font-size: 1.25rem;
}
.o-nas .container-1 .col-2 .col-2-text2 p {
  color: #383838;
}
.o-nas .container-1 .col-2 .col-2-text2 .have__q3 .start__free,
.o-nas .container-1 .col-2 .col-2-text2 .have__q3 .start__free:after {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all linear 0.2s;
}
.o-nas .container-1 .col-2 .col-2-text2 .have__q3 .start__free {
  color: #ff564f;
  padding-left: 10px;
  position: relative;
}
.o-nas .container-1 .col-2 .col-2-text2 a,
.o-nas .container-1 .col-2 .col-2-text2 a:focus,
.o-nas .container-1 .col-2 .col-2-text2 a:hover {
  text-decoration: none;
  outline: 0;
}
.o-nas .container-1 .col-2 .col-2-text2 .have__q3 .start__free:after {
  position: absolute;
  content: "";
  width: 37%;
  height: 3px;
  left: 3%;
  background: #ff564f;
  bottom: -8px;
}
.o-nas .container-1 .col-2 .col-2-text2 .have__q3 .start__free:hover:after {
  width: 100%;
  left: 3%;
}
.o-nas .container-1 .col-2 .col-2-text2 .have__q3 .start__free:hover {
  letter-spacing: 1.2px;
}
.o-nas .container-1 .col-2 .col-2-list {
  padding: 15px 0;
  list-style-type: none;
}
.o-nas .container-1 .col-2 .col-2-list svg {
  color: #ffad33;
  width: 1em;
  margin-right: 6px;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.o-nas-2 {
  padding-top: 100px;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
.o-nas-2 .container-3 {
  width: 100%;
  box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
}
.o-nas-2 .container-3 .row-1 {
  background-color: #fc7536;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.o-nas-2 .container-3 .row-1 .row-1-item1 {
  width: 100%;
  height: 800px;
}
.o-nas-2 .container-3 .row-1 .row-1-item1 img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.o-nas-2 .container-3 .row-1 .row-1-item2 {
  background-color: #fc7536;
  margin-top: auto;
  margin-bottom: auto;
}
.o-nas-2 .container-3 .row-1 .row-1-item2 .row-1-item2-content {
  padding: 20px 40px;
  width: 100%;
}
.o-nas-2 .container-3 .row-1 .row-1-item2 .row-1-item2-content .row-1-item2-text {
  color: white;
}
.o-nas-2 .container-3 .row-1 .row-1-item2 .row-1-item2-content .row-1-item2-text h5 {
  font-weight: 700;
  padding-bottom: 5px;
  font-size: 18px;
  color: black;
  font-size: 20px;
}
.o-nas-2 .container-3 .row-1 .row-1-item2 .row-1-item2-content .row-1-item2-text h2 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  padding-bottom: 4px;
}
.o-nas-2 .container-3 .row-1 .row-1-item2 .row-1-item2-content .row-1-item2-list .list-item {
  display: flex;
  padding-top: 20px;
}
.o-nas-2 .container-3 .row-1 .row-1-item2 .row-1-item2-content .row-1-item2-list .list-item .list-item-text {
  padding-top: 3px;
  color: white;
  padding-left: 20px;
}
.o-nas-2 .container-3 .row-1 .row-1-item2 .row-1-item2-content .row-1-item2-list .list-item .list-item-text h5 {
  font-size: 18px;
}
.o-nas-2 .container-3 .row-1 .row-1-item2 .row-1-item2-content .row-1-item2-list .list-item .list-item-icon {
  background: #fff;
  height: 80px;
  width: 80px;
  justify-content: center;
  padding: 15px;
  display: flex;
  border-radius: 8%;
  -webkit-border-radius: 8%;
  -moz-border-radius: 8%;
}

.o-nas-3 {
  position: relative;
  padding-top: 60px;
}
.o-nas-3 .side-img1 {
  position: absolute;
  right: 0px;
  top: 0;
  z-index: -2;
}
.o-nas-3 .side-img1 img {
  max-width: 500px;
}
.o-nas-3 .container-2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1700px;
}
.o-nas-3 .container-2 .row-1 {
  display: flex;
  flex-wrap: wrap;
}
.o-nas-3 .container-2 .row-1 .row-1-text {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 60px;
}
.o-nas-3 .container-2 .row-1 .row-1-text h5 {
  color: #1a6136;
  font-weight: 700;
  font-size: 1.5rem;
}
.o-nas-3 .container-2 .row-1 .row-1-text h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
  color: #383838;
  padding-bottom: 5px;
}
.o-nas-3 .container-2 .row-1 .row-1-text p {
  color: #383838;
}
.o-nas-3 .container-2 .row-2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.o-nas-3 .container-2 .row-2 .row-2-item {
  position: relative;
  width: 100%;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  padding-right: 15px;
  padding-left: 15px;
}
.o-nas-3 .container-2 .row-2 .row-2-item .row-2-item-img {
  max-width: 100%;
  height: auto;
}
.o-nas-3 .container-2 .row-2 .row-2-item .row-2-item-img img {
  width: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all linear 0.2s;
  vertical-align: middle;
}
.o-nas-3 .container-2 .row-2 .row-2-item .row-2-item-content {
  position: absolute;
  top: 54%;
  background: rgba(255, 255, 255, 0.808);
  left: 18%;
  width: 65%;
  height: 20%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
.o-nas-3 .container-2 .row-2 .row-2-item .row-2-item-content .row-2-item-text {
  transform: translateY(4px);
}
.o-nas-3 .container-2 .row-2 .row-2-item .row-2-item-content .row-2-item-text h5 {
  font-size: 18px;
  font-weight: 700;
  color: #383838;
}
.o-nas-3 .container-2 .row-2 .row-2-item .row-2-item-content .row-2-item-text h6 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #383838;
}

.zajecia {
  background-color: coral;
}
.zajecia .zajecia-top {
  width: 100%;
}
.zajecia .zajecia-top .zajecia-top-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 60rem;
}
.zajecia .zajecia-top .zajecia-top-text h3 {
  text-align: right;
}
.zajecia .zajecia-grid {
  display: grid;
  grid-gap: 1rem 1rem;
  max-width: 60rem;
  grid-template-columns: 3fr 1fr;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding-top: 2rem;
}
.zajecia .zajecia-grid .zajecia-grid-left {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: 1fr 1fr;
}
.zajecia .zajecia-grid .zajecia-grid-left .item1 {
  width: 15rem;
  height: 15rem;
  background-color: aliceblue;
}

.galeria {
  background-color: darkcyan;
}
.galeria .galeria-top {
  width: 100%;
}
.galeria .galeria-top .galeria-top-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 60rem;
}
.galeria .galeria-top .galeria-top-text h3 {
  text-align: right;
}
.galeria .galeria-wraper .galeria-wraper-top .galeria-wraper-top-text h4 {
  text-align: center;
}
.galeria .galeria-wraper .galeria-wraper-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 70rem;
}
.galeria .galeria-wraper .galeria-wraper-grid .item {
  width: auto;
  height: 10rem;
  background-color: aliceblue;
}

.kontakt {
  background-color: dimgrey;
}

@media only screen and (max-width: 1130px) {
  .popup .popup-inner a h1 {
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.829);
    line-height: normal;
    font-size: 20px;
  }
  .popup .popup-inner a h2 {
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.829);
    font-size: 20px;
    line-height: normal;
  }
  .popup .popup-inner img {
    display: flex;
    margin-inline: auto;
  }
  .popup .popup-inner button {
    cursor: pointer;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #699e7e;
    color: white;
    border-radius: 10px;
    font-size: 18px;
    max-width: 300px;
  }
  .popup .popup-inner a {
    margin-top: auto;
    margin-bottom: auto;
  }
  .popup .popup-inner a img {
    width: 300px;
  }
  .bar {
    overflow: hidden;
  }
  .bar .elem-8 {
    position: absolute;
    left: -82px;
    bottom: -32px;
  }
  .bar .elem-9 {
    position: absolute;
    right: -25px;
    top: 2%;
  }
  .bar .container .row .row-text h1:after {
    width: 30px;
  }
  .bar .container .row .row-text h1:before {
    width: 30px;
  }
  .heading h2 {
    display: block;
  }
  .heading h2:before {
    margin: 0;
  }
  .heading h2:after {
    margin: 0;
  }
  .footer .footer-container .row1 {
    grid-template-columns: 1fr;
  }
  .strona-glowna .strona-glowna-content {
    padding: 0;
  }
  .strona-glowna .strona-glowna-content .container {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 0;
  }
  .strona-glowna .strona-glowna-content .container .col-1 .buttons {
    padding: 10px 10px 20px;
    display: flex;
    justify-content: center;
  }
  .strona-glowna .strona-glowna-content .container .col-1 .buttons .btn {
    width: 120px;
    height: 40px;
    background-color: #ffad33;
    border: none;
  }
  .strona-glowna .strona-glowna-content .container .col-1 p {
    padding: 5px;
  }
  .strona-glowna .strona-glowna-content .container .col-1 p:last-of-type {
    padding-bottom: 5px;
  }
  .strona-glowna .strona-glowna-content .container .col-1 h1 {
    padding: 5px;
    font-size: 25px;
    text-align: center;
    line-height: 25px;
  }
  .strona-glowna .strona-glowna-content .container .col-1 h2 {
    line-height: 35px;
    padding: 5px;
    font-size: 35px;
    text-align: center;
  }
  .strona-glowna .strona-glowna-content .container .col-1 p {
    font-size: 18px;
  }
  .strona-glowna .strona-glowna-content .container .col-2 {
    margin: 10px;
  }
  .o-nas {
    padding-top: 20px;
  }
  .o-nas .container-1 {
    grid-template-columns: 1fr;
  }
  .o-nas-2 {
    padding-top: 20px;
  }
  .o-nas-2 .container-3 .row-1 {
    grid-template-columns: 1fr;
  }
  .o-nas-2 .container-3 .row-1 .row-1-item1 {
    max-height: 300px;
  }
  .o-nas-2 .container-3 .row-1 .row-1-item2 .row-1-item2-content {
    padding: 20px 10px;
  }
}/*# sourceMappingURL=home.css.map */