$main-color: #1a6136;
$secondary-color: #ffad33;
$font-color: #383838;
$highlite: #ff564f;

.oferta1 {
  margin-top: 20px;
  position: relative;
  .container-title{
    max-width: 1300px;
      margin: 0 auto;
      
    h2{color: black;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      justify-content: center;
      text-align: center;
      border-radius: 8px;
      padding: 5px 0;
      
      margin:0 20px;
    
}}
  
  .oferta1-text {
    margin: 20px;
    max-width: 1000px;
    margin: 20px auto;
    text-align: justify;
    p{
      justify-content: center;
      align-items: center;
      margin:0 20px;
    }
    
  }
  .oferta1-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    padding: 20px;
    border-radius: 12px;
    max-width: 1300px;
    margin: 0 auto;

    .oferta1-item {
     
      max-height: 240px;
      border-radius: 8px;
      
      .item-content {
        height: 100%;
        position: relative;
        
        .text {
          inset: 0;
          margin: auto;
          width: 100%;
          height: 100%;
          display: flex;
          position: absolute;
          z-index: 3;
          color: #ffffff;
          font-weight: bold;
          font-size: 1rem;
          text-align: center;
          justify-content: center;
          text-align: center;
          border-radius: 8px;
          background-color: #1a613698;
          
          h2 {
            display: flex;
            margin-top: auto;
            margin-bottom: auto;
            text-shadow: (0px 3px 5px rgba(0, 0, 0, 0.529));
            padding: 20px;            
          }
          
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-bottom: 6px solid $main-color;
          border-radius: 8px;
          
        }
      }
    }
    .oferta1-item:last-child{
      h2{
        font-size: 20px;
      }
    }
  }
}
.oferta2{
  max-width: 1300px;
  margin: 20px auto;
  
  .oferta2-container{
    margin: 0 20px;
   display: flex;
   flex-direction: row;
    .oferta2-img{
      display: flex;
      flex:2;
      img{
        width: 100%;
          height: 100%;
          object-fit: cover;
          
      }
    }
    .oferta2-text{
      display: flex;
      flex: 4;
      flex-direction: column;
      justify-content: center;
      margin-left: 40px;
      h3{
        line-height: 40px;
        color: $font-color;
        
        i{
          color: $main-color;
          font-size: 24px;
        text-justify: center;
        }
        
      }
      h3:last-child{
        background-color: $main-color;
        color: white;
        padding: 20px 10px;
        text-align: center;
        font-size: 26px;
        border-radius: 8px;
        text-shadow: (0px 3px 5px rgba(0, 0, 0, 0.529));
        margin-top: 20px;
        i{
          color: white;
        }
      }
    }
  }
}
.oferta3{
  margin: 0 20px;
  .oferta3-container{
    max-width: 1000px;
  margin: 20px auto;
    display: flex;

  
    .oferta3-text{
      justify-content: center;
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      
      .oferta3-text2{
        display: flex;
       margin-top: 40px;
          align-items: center;
          color: $font-color;
          .start__free,
          .start__free:after {
            transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all linear 0.2s;
          }
          .start__free {
            color: #ff564f;
            padding-left: 10px;
            position: relative;
            font-size: 26px;
          }
          a,
          a:focus,
          a:hover {
            text-decoration: none;
            outline: 0;
          }
          .start__free:after {
            position: absolute;
            content: "";
            width: 37%;
            height: 3px;
            left: 4%;
            background: #ff564f;
            bottom: -8px;
          }
          .start__free:hover:after {
            width: 100%;
            left: 2%;
          }
          .start__free:hover {
            letter-spacing: 1.2px;
          }
      }
    
        
        
      }
    
    .oferta3-items{
      box-shadow: 3px 3px 10px -1px rgba(0, 0, 0, 0.5);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      padding: 20px;
      border-radius: 10px;
      height: 375px;
      width: 375px;
      .oferta3-item1{
        padding: 10px;
        border: solid 8px  rgb(0, 97, 177) ;
        color: black;
        height:10rem;
        width:10rem;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        h3{
          text-align: center; 
          margin: auto 0 ;
          
        }
      }
      .oferta3-item2{
        padding: 20px;
        border: solid 8px $secondary-color;
        border-radius: 10px;
        height:10rem;
        width:10rem;
         display: flex;
         justify-content: center;
        h3{
          text-align: center; 
          margin: auto 0 ;
        }
      }
      .oferta3-item3{
        padding: 20px;
        border: solid 8px $main-color;
        height:10rem;
        width:10rem;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        h3{
          text-align: center; 
          margin: auto 0 ;
        }
      }
      .oferta3-item4{
        padding: 20px;
        border: solid 8px  rgb(121, 67, 221);
        color: black;
        height:10rem;
        width:10rem;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        h3{
          text-align: center; 
          margin: auto 0 ;
        }
      }
    }
  }
}
@media only screen and (max-width: 1130px){
  
  .oferta1{
    .oferta1-container{
      grid-template-columns:1fr 1fr;
      grid-gap:10px;
      .oferta1-item{
        height: 40vh;
        
        .item-content{
          .text{
            font-size: 16px;
            h2{
              padding: 5px;
            }
          }
        }
      }
      .oferta1-item:last-child{
        h2{
          font-size: 18px;
        }
      }
      
    }
  }
  .oferta2{
    .oferta2-container{
      flex-direction: column;
      .oferta2-img{
        max-width: 330px;
        margin-inline: auto ;
      }
      .oferta2-text{
        margin-top:20px ;
        margin-left:0;
        h3{
          line-height: 30px;
        }
      }
    }
  }
  .oferta3{
    .oferta3-container{
      flex-direction: column-reverse;
      margin:0 auto 20px;
      .oferta3-text{
        margin-right: 0px;
        
      }
.oferta3-items{
  padding: 0;
  margin:20px 0;
  grid-gap: 3px;
  height: auto;
  width: auto;
  box-shadow: none;
  .oferta3-item1{
    width: 100%;
padding: 10px;
  }
  .oferta3-item2{
    width: 100%;
padding: 10px;
  }
  .oferta3-item3{
    width: 100%;
padding: 10px;
  }
  .oferta3-item4{
    width: 100%;
padding: 10px;
  }
}
    }
  }
  
}