.przedszkole1 {
  margin: 20px auto;
  max-width: 1600px;
}
.przedszkole1 .grupy {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}
.przedszkole1 .grupy .grupa-1,
.przedszkole1 .grupy .grupa-2,
.przedszkole1 .grupy .grupa-3 {
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin: 20px 0px;
  box-shadow: 3px 3px 13px -4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  min-height: 200px;
}
.przedszkole1 .grupy .grupa-1 .grupa-col1 .grupa-img,
.przedszkole1 .grupy .grupa-2 .grupa-col1 .grupa-img,
.przedszkole1 .grupy .grupa-3 .grupa-col1 .grupa-img {
  height: 100%;
  position: relative;
}
.przedszkole1 .grupy .grupa-1 .grupa-col1 .grupa-img .img-title,
.przedszkole1 .grupy .grupa-2 .grupa-col1 .grupa-img .img-title,
.przedszkole1 .grupy .grupa-3 .grupa-col1 .grupa-img .img-title {
  float: left;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 3;
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  text-align: center;
  border-bottom-left-radius: 5px;
}
.przedszkole1 .grupy .grupa-1 .grupa-col1 .grupa-img img,
.przedszkole1 .grupy .grupa-2 .grupa-col1 .grupa-img img,
.przedszkole1 .grupy .grupa-3 .grupa-col1 .grupa-img img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.przedszkole1 .grupy .grupa-1 .grupa-col2,
.przedszkole1 .grupy .grupa-2 .grupa-col2,
.przedszkole1 .grupy .grupa-3 .grupa-col2 {
  height: 100%;
  width: 100%;
  display: flex;
}
.przedszkole1 .grupy .grupa-1 .grupa-col2 .grupa-text,
.przedszkole1 .grupy .grupa-2 .grupa-col2 .grupa-text,
.przedszkole1 .grupy .grupa-3 .grupa-col2 .grupa-text {
  padding: 10px 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.przedszkole1 .grupy .grupa-1 .grupa-col1 .grupa-img .img-title {
  background-color: rgba(255, 173, 51, 0.4392156863);
}
.przedszkole1 .grupy .grupa-1 .grupa-col2 {
  background-color: rgba(255, 173, 51, 0.2);
  border-left: #ffad33 solid 10px;
}
.przedszkole1 .grupy .grupa-2 .grupa-col1 .grupa-img .img-title {
  background-color: rgba(140, 62, 243, 0.6509803922);
}
.przedszkole1 .grupy .grupa-2 .grupa-col2 {
  background-color: rgba(140, 62, 243, 0.1568627451);
  border-left: #7b1afa solid 10px;
}
.przedszkole1 .grupy .grupa-3 .grupa-col1 .grupa-img .img-title {
  background-color: rgba(26, 97, 54, 0.5019607843);
}
.przedszkole1 .grupy .grupa-3 .grupa-col2 {
  background-color: rgba(26, 97, 54, 0.1647058824);
  border-left: #1a6136 solid 10px;
}

.przedszkole2 {
  margin: 20px auto;
  max-width: 1240px;
}
.przedszkole2 .plan {
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
  margin: 0 20px;
  margin-top: 20px;
}
.przedszkole2 .plan .plan-img {
  max-height: 200px;
  position: relative;
}
.przedszkole2 .plan .plan-img .plan-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(26, 97, 54, 0.8980392157);
  color: white;
  padding: 20px 30px;
  border-radius: 5px;
  font-size: 22px;
  border: 2px solid #ffad33;
}
.przedszkole2 .plan .plan-img img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-height: 200px;
  border-bottom: #ffad33 3px solid;
}
.przedszkole2 .plan table {
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(26, 97, 54, 0.9450980392);
  color: white;
}
.przedszkole2 .plan table td {
  min-width: 130px;
  padding: 5px;
  vertical-align: top;
}
.przedszkole2 .plan table td:first-child {
  padding-left: 20px;
}
.przedszkole2 .plan table td:last-child {
  padding-right: 20px;
}
.przedszkole2 .plan table tr:nth-of-type(2n) {
  background-color: rgba(26, 97, 54, 0.862745098);
}
.przedszkole2 .plan .foot {
  background-color: #ffad33;
  height: 30px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.przedszkole4 {
  margin: 20px auto;
  max-width: 1300px;
  padding-bottom: 40px;
  padding: 0 20px;
  padding-top: 10px;
}
.przedszkole4 .przedszkole4-container {
  margin: 20px auto;
  max-width: 600px;
  box-shadow: 3px 3px 13px -4px rgba(0, 0, 0, 0.5);
}
.przedszkole4 .przedszkole4-container table {
  width: 100%;
  border-collapse: collapse;
}
.przedszkole4 .przedszkole4-container table td {
  padding: 5px;
  vertical-align: auto;
  text-align: center;
}
.przedszkole4 .przedszkole4-container table tr:first-child {
  font-weight: 600;
  background-color: #1a6136;
  color: white;
}
.przedszkole4 .przedszkole4-container table tr:nth-of-type(2n) {
  background-color: rgba(26, 97, 54, 0.1568627451);
}
.przedszkole4 .przedszkole4-container table,
.przedszkole4 .przedszkole4-container td {
  border: 1px solid #15703a;
}

.przedszkole5 {
  margin: 20px;
}
.przedszkole5 .image-gallery {
  margin: 0 auto;
  padding: 20px;
  max-width: 1400px;
}
.przedszkole5 .image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  padding: 0;
  z-index: 5 !important;
}
.przedszkole5 .image-gallery-thumbnail.active,
.przedszkole5 .image-gallery-thumbnail:focus {
  border: 4px solid #1a6136 !important;
}
.przedszkole5 .image-gallery-thumbnail:hover {
  border: 4px solid #1a6136 !important;
}
.przedszkole5 .image-gallery-icon:hover {
  color: #1a6136 !important;
}
.przedszkole5 .image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(85vh - 80px) !important;
}

.image-gallery-content.fullscreen {
  background: #000;
  z-index: 30;
}

.image-gallery-fullscreen-button {
  display: none;
}

@media only screen and (max-width: 1130px) {
  .przedszkole1 .grupy .grupa-1,
.przedszkole1 .grupy .grupa-2,
.przedszkole1 .grupy .grupa-3 {
    grid-template-columns: 1fr;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .przedszkole1 .grupy .grupa-1 .grupa-col1 .grupa-img .img-title,
.przedszkole1 .grupy .grupa-2 .grupa-col1 .grupa-img .img-title,
.przedszkole1 .grupy .grupa-3 .grupa-col1 .grupa-img .img-title {
    border-bottom-left-radius: 0px;
  }
  .przedszkole1 .grupy .grupa-1 .grupa-col1 .grupa-img img,
.przedszkole1 .grupy .grupa-2 .grupa-col1 .grupa-img img,
.przedszkole1 .grupy .grupa-3 .grupa-col1 .grupa-img img {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
  }
  .przedszkole1 .grupy .grupa-1 .grupa-col2,
.przedszkole1 .grupy .grupa-2 .grupa-col2,
.przedszkole1 .grupy .grupa-3 .grupa-col2 {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .przedszkole2 .plan {
    margin: 0 5px;
  }
  .przedszkole2 .plan .plan-img .plan-title {
    font-size: 22px;
    padding: 10px;
    text-align: center;
    width: 80vw;
  }
  .przedszkole2 .plan table td {
    min-width: 110px;
    padding: 5px;
    vertical-align: top;
  }
  .przedszkole2 .plan table td:first-child {
    padding-left: 5px;
  }
  .przedszkole2 .plan table td:last-child {
    padding-right: 5px;
  }
  .przedszkole5 {
    margin: 20px 5px;
  }
  .przedszkole5 .image-gallery {
    padding: 0px;
  }
}/*# sourceMappingURL=przedszkole.css.map */