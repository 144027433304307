.plakat {
  margin: 10px;
  display: flex;
  justify-content: center;
}
.plakat img {
  max-height: 90vh;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}/*# sourceMappingURL=dzienotwarty.css.map */