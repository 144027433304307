.aktualnosci {
  max-width: 1100px;
  position: relative;
  margin: 20px auto;
}
.aktualnosci .aktualnosci-container {
  display: flex;
  gap: 20px;
  margin: 40px;
}
.aktualnosci .aktualnosci-container .aktualnosci-item {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.aktualnosci .aktualnosci-container .aktualnosci-item .item-img {
  padding: 10px;
  background-color: rgba(255, 173, 51, 0.4784313725);
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.aktualnosci .aktualnosci-container .aktualnosci-item .item-img img {
  width: 300px;
  height: 300px;
  border-radius: 8px;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
.aktualnosci .aktualnosci-container .aktualnosci-item .item-text {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  color: #383838;
}
.aktualnosci .aktualnosci-container .aktualnosci-item:hover {
  box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 1130px) {
  .aktualnosci .aktualnosci-container {
    flex-direction: column;
    margin-inline: auto;
    justify-content: center;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    align-items: center;
  }
  .aktualnosci .aktualnosci-container .aktualnosci-item .item-text {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}/*# sourceMappingURL=aktualnosci.css.map */