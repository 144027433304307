$main-color: #1a6136;
$secondary-color: #ffad33;
$font-color: #383838;
$highlite: #ff564f;

.przedszkole1 {
  margin: 20px auto;
  max-width: 1600px;
  

  
  .grupy {
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
    .grupa-1,
    .grupa-2,
    .grupa-3 {
      display: grid;
      grid-template-columns: 1fr 4fr;
      margin: 20px 0px;
      box-shadow: 3px 3px 13px -4px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      min-height: 200px;

      .grupa-col1 {
        .grupa-img {
          height: 100%;
          position: relative;
          .img-title {
            float: left;
            position: absolute;
            left: 0px;
            bottom: 0px;
            z-index: 3;
            padding: 5px;
            color: #ffffff;
            font-weight: bold;
            font-size: 18px;
            width: 100%;
            text-align: center;
            border-bottom-left-radius: 5px;
          }
          img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }
      }
      .grupa-col2 {
        height: 100%;
        width: 100%;
        display: flex;

        .grupa-text {
          padding: 10px 20px;
          margin-top: auto;
          margin-bottom: auto;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    .grupa-1 {
      .grupa-col1 {
        .grupa-img {
          .img-title {
            background-color: #ffad3370;
          }
        }
      }
      .grupa-col2 {
        background-color: #ffad3333;
        border-left: #ffad33 solid 10px;
      }
    }
    .grupa-2 {
      .grupa-col1 {
        .grupa-img {
          .img-title {
            background-color: #8c3ef3a6;
          }
        }
      }
      .grupa-col2 {
        background-color: #8c3ef328;
        border-left: #7b1afa solid 10px;
      }
    }
    .grupa-3 {
      .grupa-col1 {
        .grupa-img {
          .img-title {
            background-color: #1a613680;
          }
        }
      }
      .grupa-col2 {
        background-color: #1a61362a;
        border-left: #1a6136 solid 10px;
      }
    }
  }
}
.przedszkole2 {
  margin: 20px auto;
  max-width: 1240px;

  
  .plan {
    
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 8px;
    box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
    margin: 0 20px;
    margin-top: 20px;
    .plan-img {
      max-height: 200px;
      position: relative;
      .plan-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #1a6136e5;
        color: white;
        padding: 20px 30px;
        border-radius: 5px;
        font-size: 22px;
        border: 2px solid #ffad33;
      }
      img {
        object-fit: cover;
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        max-height: 200px;
        border-bottom: #ffad33 3px solid;
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      background-color: #1a6136f1;
      color: white;

      td {
        min-width: 130px;
        padding: 5px;
        vertical-align: top;
      }
      td:first-child {
        padding-left: 20px;
      }
      td:last-child {
        padding-right: 20px;
      }
      tr:nth-of-type(2n) {
        background-color: #1a6136dc;
      }
    }
    .foot {
      background-color: #ffad33;
      height: 30px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden;
    }
  }
}

.przedszkole4 {
  margin: 20px auto;
  max-width: 1300px;
  padding-bottom: 40px;
  padding: 0 20px;
  padding-top: 10px;
  
  .przedszkole4-container {
    margin: 20px auto;
    max-width: 600px;
    box-shadow: 3px 3px 13px -4px rgba(0, 0, 0, 0.5);

    table {
      width: 100%;
      border-collapse: collapse;
      td {
        padding: 5px;
        vertical-align: auto;
        text-align: center;
      }
      tr:first-child {
        font-weight: 600;
        background-color: #1a6136;
        color: white;
      }
      tr:nth-of-type(2n) {
        background-color: #1a613628;
      }
    }
    table,
    td {
      border: 1px solid #15703a;
    }
  }
}
.przedszkole5 {
  margin:20px;
  
  
  .image-gallery {
    margin: 0 auto;
    padding: 20px;
    max-width: 1400px;
    
  }
  
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    padding: 0;
    z-index: 5!important;
  }
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus {
    border: 4px solid$main-color !important;
  }
  .image-gallery-thumbnail:hover {
    border: 4px solid $main-color !important;
  }
  .image-gallery-icon:hover {
    color: $main-color !important;
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(85vh - 80px) !important;
  }
}
.image-gallery-content.fullscreen {
  background: #000;
  z-index: 30;
}
.image-gallery-fullscreen-button{
  display: none;
}
@media only screen and (max-width: 1130px) {

  .przedszkole1{
    .grupy{
      .grupa-1,
      .grupa-2,
      .grupa-3 {
        grid-template-columns: 1fr ;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .grupa-col1 {
          .grupa-img{
            .img-title{
              border-bottom-left-radius: 0px;
            }
            img{
              border-bottom-left-radius: 0px;
              border-top-right-radius: 5px;
            }
          }
        }
        .grupa-col2{
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        
      }
    }
  }
  .przedszkole2{
    .plan{
      margin: 0 5px;
      .plan-img{
        .plan-title{
          font-size: 22px;
          padding: 10px;
          text-align: center;
          width:80vw;
          
        }
      }
      table{
        td {
          min-width: 110px;
          padding: 5px;
          vertical-align: top;
        }
        td:first-child {
          padding-left: 5px;
        }
        td:last-child {
          padding-right: 5px;
        }
        
      }
    }
  }
  .przedszkole5 {
    margin: 20px 5px;
    .image-gallery {
      padding: 0px;
    }
    
  }
}
