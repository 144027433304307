$main-color: #1a6136;
$secondary-color: #ffad33;
$font-color: #383838;
$highlite: #ff564f;

.aktualnosci{
    max-width: 1100px;
    position: relative;
    margin: 20px auto;
    .aktualnosci-container{
        display: flex;
        gap: 20px;
        margin: 40px;
        .aktualnosci-item{
            
            width:min-content;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            
            
            .item-img{
                padding: 10px;
                background-color: #ffad337a;
                border-radius: 10px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                
                
                img{
                    width: 300px;
                height: 300px;
                    border-radius: 8px;
                    display: block;
                    object-fit: cover;

                   
                }
            }
            .item-text{
                padding: 10px;
                display: flex;
                flex-direction: column;
               justify-content: space-between;
               height: 140px;
               color: #383838;
                
            }
        }
        .aktualnosci-item:hover{
            box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.8);
        }
    }
}
@media only screen and (max-width: 1130px) {
    .aktualnosci{
        .aktualnosci-container{
            flex-direction: column;
            margin-inline: auto;
            justify-content: center;
            max-width:fit-content;
            align-items: center;
            .aktualnosci-item{
                .item-text{
                    height: fit-content;
                }
            }
        }
    }
}