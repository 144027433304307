* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$main-color: #1a6136;
$secondary-color: #ffad33;
$font-color: #383838;
$highlite: #ff564f;

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: white;
}

html,
body {
  position: relative;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  background-color: white;
}
button {
  font-family: "Roboto", sans-serif;
}
header {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}
nav {
  width: 100%;

  border-radius: 0;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 25;
  padding: 0.8rem 2.5rem;
  background-color: transparent;
  position: fixed;

  .links {
    margin: auto;
    display: flex;
    height: 50px;
    border-radius: 30px;
    list-style: none;
    background: transparent;
    

    a {
      display: flex;
      align-items: center;
      padding-left: 13px;
      padding-right: 13px;
      height: 100%;
      border-radius: 10px;
      text-decoration: none;
      background-color: #699e7e;
      margin-left: 8px;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.404));

      font-size: 18px;
      font-weight: 400;
      transition: background-color 0.3s, box-shadow 0.3s, color 0.3s ;
      white-space: nowrap;
      &:hover {
        background-color: $secondary-color;
        box-shadow: inset 0 0 0 #ffad33, 0 0 0.8em #ffad33;
      }
    }
    .active {
      background-color: $secondary-color;
    }
  }
  .links-mobile {
    width: 260px;
    display: flex;
    justify-content: flex-end;
    // transition: all 0.1s ease-in;
    .hamburger {
      display: none;
    }
  }
  a{
    display: flex;
  .logo {
    display: flex;
    width: 260px;
    transition: all 0.2s ease;
    
      img {
        width: 260px;
        transition: all 0.2s ease;
        margin: auto 0;
      }
    }

    
  }
}
.navbar-scroll {
  background-color: white;
  box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  
  .logo{
    transition: all 0.2s ease;
    img{
      width: 160px;
      transition: all 0.2s ease;
    }
  }
}
.heading{
 
  h2 {
    line-height: 36px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: $font-color;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    max-width: 580px;
    align-items: center;
    border-radius: 10px;
    padding: 20px 20px;
    
    margin: 0 auto;
    position: relative;
    z-index: 10;
   
  }
  h2:before {
    content: " ";
    display: block;
    border-bottom: 3px solid $secondary-color;
    height: 1px;
    margin: 0 20px 0 0;
  }
  h2:after {
    content: " ";
    display: block;
    border-top: 3px solid $secondary-color;
    height: 1px;
    margin: 0 0 0 20px;
  }
}
.heading2{
 
  h2 {
    line-height: 36px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: $font-color;
    letter-spacing: 0px;
    text-transform: uppercase;
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    max-width: 410px;
    align-items: center;
    border-radius: 10px;
    padding: 20px 20px;
    
    margin: 0 auto;
    position: relative;
    z-index: 10;
   
  }
  h2:before {
    content: " ";
    display: block;
    border-bottom: 3px solid $secondary-color;
    height: 1px;
    margin: 0 20px 0 0;
  }
  h2:after {
    content: " ";
    display: block;
    border-top: 3px solid $secondary-color;
    height: 1px;
    margin: 0 0 0 20px;
  }
}
.popup{
  position: fixed;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background-color: #000000a9;
 top:0;
 left:0;
 height: 100vh;
  width: 100%;
  margin-bottom: auto;
margin-inline: auto;
  z-index: 100;
 .popup-inner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border: 5px solid #1a6136;
  background-color:#000000b2;
  border-radius: 10px;
  padding: 3%;
  margin: 3%;
  a{
    h1{
    text-shadow:(0px 1px 4px rgba(0, 0, 0, 0.829)) ;
    line-height: normal;
    margin-top: 3%;
    margin-bottom: 3%;
    font-size: 30px;
  }
  h2{
    text-shadow:(0px 1px 4px rgba(0, 0, 0, 0.829)) ;
    font-size: 30px;
  }
}
  img{
    display: flex;
   margin-inline: auto;
  }
  button{
    
    cursor: pointer;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #699e7e;
    color: white;
    border-radius: 10px;
    font-size: 18px;
    max-width: 300px;
  }
  a{
    margin-top: auto;
    margin-bottom: auto;
    img{
      max-width: 300px;
      
    }
    
  }
 }
  
  
  
}
.bar {
  margin-top: 126px;
  background: $main-color;
  padding: 20px 0;

  position: relative;
 
  .elem-8 {
    position: absolute;
    left: -42px;
    bottom: -12px;
  }
  .elem-9 {
    position: absolute;
    right: 0;
    top: 32%;
  }

  .container {
    .row {
      .row-text {
        display: flex;
        justify-content: center;
        h1 {
          font-size: 2.4rem;
          line-height: 36px;
          font-weight: 600;
          color: white;
        }
        h1:after {
          display: inline-block;
          margin: 0 0 8px 20px;
          height: 3px;
          content: " ";
          text-shadow: none;
          background-color: $secondary-color;
          width: 80px;
        }
        h1:before {
          display: inline-block;
          margin: 0 20px 8px 0;
          height: 3px;
          content: " ";
          text-shadow: none;
          background-color: $secondary-color;
          width: 80px;
        }
      }
    }
  }
}

.footer {
  background: #262729;
  color: #fff;

  .footer-container {
    max-width: 1400px;
    padding: 40px 20px 0 20px;
    margin: 0 auto;
    .row1 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      .item {
        

        .item-container {
          margin-top: auto;
          margin-bottom: auto;
          height: 100%;
          .item-list {
            list-style: none;
          }
        }
      }
    }
    .row2 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .row2-text {
        width: 100%;
        .copy_footer {
          font-size: 14px;
          font-weight: 300;
          margin-top: 38px;
          padding: 25px 0;
          border-top: 1px solid $secondary-color;
          text-align: center;
        }
      }
    }
  }
}
.strona-glowna {
  margin: 0 2%;
  padding-top: 126px;
  
  .strona-glowna-content {
    max-width: 1700px;
    min-height: auto;
    background-color: $main-color;
    padding: 80px 0;
    position: relative;
    margin: 0 auto;
    border-radius: 30px;
    box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      .col-1 {
        position: relative;
        padding: 0 15px;
        margin-top: auto;
        margin-bottom: auto;
        
        h1 {
          line-height: 50px;
          font-size: 35px;
          font-weight: 500;
          padding: 10px 20px 2px;
          color: white;
          text-shadow:(0px 1px 3px rgba(0, 0, 0, 0.329)) ;
        }
        h2 {
          line-height: 70px;
          font-size: 50px;
          font-weight: 700;
          padding: 10px 20px 2px;
          color: white;
          text-shadow:(0px 1px 3px rgba(0, 0, 0, 0.329)) ;
        }
        p {
          line-height: 20px;
          font-size: 20px;
          font-weight: 300;
          padding: 10px 20px;
          color: white;
          text-shadow:(0px 1px 3px rgba(0, 0, 0, 0.329)) ;
        }
        p:last-of-type {
          font-size: 14px;
          padding-bottom: 40px;
        }
        .buttons {
          padding: 10px 20px 2px;
          a:last-child{
            margin-left: 20px;
          }
          .btn {
            cursor: pointer;
            background: transparent;
            color: white;
            width: 148px;
            height: 50px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            
            display: inline-flex;
            border: 1px solid #fff;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.707));
           
            position: relative;
            z-index: 22;
          }
          
          :after {
            
            box-sizing: border-box;
            content: "";
            background: $secondary-color;
            border-radius: 4px;

            z-index: -1;
            transform: scaleX(0);
            transform-origin: 0 50%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            transition-property: transform;
            transition-timing-function: ease-out;
            transition-duration: 0.2s;
            position: absolute;
          }
          :hover::after {
            transform: scaleX(1);
          }
          :hover {
            border: $secondary-color;
          }
        }
      }
    }
    .col-2 {
      position: relative;
      margin-right: 60px;
      height: 100%;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 25px;
        max-height: 500px;

        box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
      }
    }
  }
}

.o-nas {
  
  position: relative;
  padding-top: 100px;
  margin: 20px auto;
  max-width: 1700px;
  .container-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin: 0 2%;
    .col-1 {
      background-color: $main-color;
      padding: 8px;
      width: 100%;
      object-fit: cover;
      height: 100%;
      box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;
      border-radius: 25px;
      ::after {
        position: absolute;

        left: -4%;
        top: -7%;
        z-index: 1;
      }
      .col-1-img {
        width: 100%;
        object-fit: cover;
        height: 100%;

        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          border-radius: 20px;
          
        }
      }
    }
    .col-2 {
      .elem-4 {
        position: absolute;
        bottom: 6%;
        right: 3%;
        z-index: -1;
      }
      .col-2-text {
        h2 {
          font-size: 35px;
          line-height: 45px;
          font-weight: 700;
          color: $font-color;
        }
        p {
          color: $font-color;
        }
        h5 {
          padding-bottom: 5px;
          color: $secondary-color;
          font-weight: 700;
          font-size: 1.25rem;
        }
      }
      .col-2-text2 {
        h5 {
          padding-bottom: 5px;
          color: $font-color;
          font-weight: 700;
          font-size: 1.25rem;
        }
        p {
          color: $font-color;
        }
        .have__q3 .start__free,
        .have__q3 .start__free:after {
          transition: all 0.2s linear;
          -webkit-transition: all 0.2s linear;
          -moz-transition: all linear 0.2s;
        }
        .have__q3 .start__free {
          color: #ff564f;
          padding-left: 10px;
          position: relative;
        }
        a,
        a:focus,
        a:hover {
          text-decoration: none;
          outline: 0;
        }
        .have__q3 .start__free:after {
          position: absolute;
          content: "";
          width: 37%;
          height: 3px;
          left: 3%;
          background: #ff564f;
          bottom: -8px;
        }
        .have__q3 .start__free:hover:after {
          width: 100%;
          left: 3%;
        }
        .have__q3 .start__free:hover {
          letter-spacing: 1.2px;
        }
      }
      .col-2-list {
        padding: 15px 0;

        list-style-type: none;

        svg {
          color: $secondary-color;
          width: 1em;
          margin-right: 6px;
          display: inline-block;
          font-size: inherit;
          height: 1em;
          overflow: visible;
          vertical-align: -0.125em;
        }
      }
    }
  }
}

.o-nas-2 {
  padding-top: 100px;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  
  .container-3 {
    width: 100%;
    box-shadow: inset 0 0 0 #000000, 0 0 0.8em #000000;

    .row-1 {
      background-color: #fc7536;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .row-1-item1 {
        width: 100%;
        height: 800px;

        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
        }
      }
      .row-1-item2 {
        background-color: #fc7536;
        margin-top: auto;
        margin-bottom: auto;
        .row-1-item2-content {
          padding: 20px 40px;
          width: 100%;

          .row-1-item2-text {
            color: white;

            h5 {
              font-weight: 700;
              padding-bottom: 5px;
              font-size: 18px;
              color: black;
              font-size: 20px;
            }
            h2 {
              font-size: 48px;
              line-height: 56px;
              font-weight: 700;
              padding-bottom: 4px;
            }
          }
          .row-1-item2-list {
            .list-item {
              display: flex;
              padding-top: 20px;
              .list-item-text {
                padding-top: 3px;
                color: white;
                padding-left: 20px;
                h5 {
                  font-size: 18px;
                }
              }
              .list-item-icon {
                background: #fff;
                height: 80px;
                width: 80px;
                justify-content: center;
                padding: 15px;
                display: flex;
                border-radius: 8%;
                -webkit-border-radius: 8%;
                -moz-border-radius: 8%;
              }
            }
          }
        }
      }
    }
  }
}
.o-nas-3 {
  position: relative;
  padding-top: 60px;

  .side-img1 {
    position: absolute;
    right: 0px;
    top: 0;
    z-index: -2;
    img {
      max-width: 500px;
    }
  }

  .container-2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 1700px;
    .row-1 {
      display: flex;
      flex-wrap: wrap;
      .row-1-text {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding-bottom: 60px;
        h5 {
          color: $main-color;
          font-weight: 700;
          font-size: 1.5rem;
        }
        h2 {
          font-size: 35px;
          line-height: 45px;
          font-weight: 700;
          color: $font-color;
          padding-bottom: 5px;
        }
        p {
          color: $font-color;
        }
      }
    }
    .row-2 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .row-2-item {
        position: relative;
        width: 100%;
        flex: 0 0 25%;
        max-width: 25%;
        position: relative;
        margin-bottom: 30px;
        overflow: hidden;
        padding-right: 15px;
        padding-left: 15px;
        .row-2-item-img {
          max-width: 100%;
          height: auto;
          img {
            width: 100%;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all linear 0.2s;
            vertical-align: middle;
          }
        }
        .row-2-item-content {
          position: absolute;
          top: 54%;
          background: rgba(255, 255, 255, 0.808);
          left: 18%;
          width: 65%;
          height: 20%;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          text-align: center;
          justify-content: center;
          align-items: center;
          display: inline-flex;
          .row-2-item-text {
            transform: translateY(4px);
            h5 {
              font-size: 18px;
              font-weight: 700;
              color: $font-color;
            }
            h6 {
              font-size: 14px;
              text-transform: uppercase;
              font-weight: 600;
              color: $font-color;
            }
          }
        }
      }
    }
  }
}
.zajecia {
  .zajecia-top {
    width: 100%;
    .zajecia-top-text {
      margin-left: auto;
      margin-right: auto;
      max-width: 60rem;
      h3 {
        text-align: right;
      }
    }
  }
  .zajecia-grid {
    display: grid;
    grid-gap: 1rem 1rem;
    max-width: 60rem;
    grid-template-columns: 3fr 1fr;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding-top: 2rem;
    .zajecia-grid-left {
      display: grid;
      grid-gap: 1rem 1rem;
      grid-template-columns: 1fr 1fr;
      .item1 {
        width: 15rem;
        height: 15rem;
        background-color: aliceblue;
      }
    }
  }
  background-color: coral;
}
.galeria {
  background-color: darkcyan;
  .galeria-top {
    width: 100%;
    .galeria-top-text {
      margin-left: auto;
      margin-right: auto;
      max-width: 60rem;
      h3 {
        text-align: right;
      }
    }
  }
  .galeria-wraper {
    .galeria-wraper-top {
      .galeria-wraper-top-text {
        h4 {
          text-align: center;
        }
      }
    }
    .galeria-wraper-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.5rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 70rem;
      .item {
        width: auto;
        height: 10rem;
        background-color: aliceblue;
      }
    }
  }
}
.kontakt {
  background-color: dimgrey;
}

@media only screen and (max-width: 1130px) {
  .popup{
   
   .popup-inner{
   
    a{
      h1{
      text-shadow:(0px 1px 4px rgba(0, 0, 0, 0.829)) ;
      line-height: normal;
      font-size: 20px;
    }
    h2{
      text-shadow:(0px 1px 4px rgba(0, 0, 0, 0.829)) ;
      font-size: 20px;
      line-height: normal;
    }
  }
    img{
      display: flex;
     margin-inline: auto;
    }
    button{
      
      cursor: pointer;
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #699e7e;
      color: white;
      border-radius: 10px;
      font-size: 18px;
      max-width: 300px;
    }
    a{
      margin-top: auto;
      margin-bottom: auto;
      img{
        width: 300px;
        
      }
      
    }
   }
    
    
    
  }
.bar{
  overflow: hidden;
  .elem-8 {
    position: absolute;
    left: -82px;
    bottom: -32px;
  }
  .elem-9 {
    position: absolute;
    right: -25px;
    top: 2%;
   
  }
  .container{
    .row{
      .row-text{
        h1:after{
width: 30px;
        }
        h1:before{
          width: 30px;
                  }
      }
    }
  }
}

  .heading{
  h2{
    display: block;
    
  }
  h2:before {
    margin: 0;
}
h2:after{
  margin:0;
}
}

  .footer{
  .footer-container{
    .row1 {
      grid-template-columns: 1fr ;
    }
    
  }
  
}

.strona-glowna {
 
  
  .strona-glowna-content {
    padding: 0;
    .container{
      display: flex;
  flex-direction: column-reverse;
  grid-gap: 0;
  .col-1{
    .buttons{
      padding: 10px 10px 20px;
      display: flex;
      justify-content: center;
     
      .btn{
        width: 120px;
        height: 40px;
        background-color: $secondary-color;
        border: none;
      }
    }
    p{
      padding: 5px;
    }
    p:last-of-type{
      padding-bottom: 5px;
    }
    h1{
      padding: 5px;
      font-size: 25px;
      text-align: center;
      line-height: 25px;
    }
    h2{
      line-height: 35px;
      padding: 5px;
      font-size: 35px;
      text-align: center;
    }
    p{
      font-size:18px ;
    }
  }
  .col-2{
    
    margin:10px;
  }
    }
  }
}

.o-nas{
  padding-top: 20px;
  .container-1{
    grid-template-columns: 1fr ;
    
  }
}
.o-nas-2{
  padding-top:20px;
.container-3{
  .row-1{
    grid-template-columns: 1fr ;
    .row-1-item1{
      max-height: 300px;
    }
    .row-1-item2{
      .row-1-item2-content{
        padding:20px 10px;
      }
    }
  }
}
}
}