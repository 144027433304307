$main-color: #1a6136;
$secondary-color: #ffad33;
$font-color: #383838;
$highlite: #ff564f;

.rekrutacja1 {
    @mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
        transition: $property $duration $ease;
      }
      .karta-text{
        text-align: left;
        max-width: 1000px;
        margin: 0 auto;
        padding: 20px 0;
        h3{
            margin-inline: 20px;
            span{
                color: #004b1e;
                text-decoration: underline;
            }
        }
        .start__free,
          .start__free:after {
            transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all linear 0.2s;
          }
          .start__free {
            color: #ff564f;
            padding-left: 8px;
            position: relative;
            font-size: 26px;
          }
          a,
          a:focus,
          a:hover {
            text-decoration: none;
            outline: 0;
          }
          .start__free:after {
            position: absolute;
            content: "";
            width: 37%;
            height: 3px;
            left: 4%;
            background: #ff564f;
            bottom: -8px;
          }
          .start__free:hover:after {
            width: 100%;
            left: 2%;
          }
          .start__free:hover {
            letter-spacing: 1.2px;
          }
      }
      .karta-button{
        margin: 40px auto;
      button {
       
          position: relative;
          display: inline-block;
          cursor: pointer;
          outline: none;
          border: 0;
          vertical-align: middle;
          text-decoration: none;
          background: #1a61362d;
          padding: 0;
          font-size: inherit;
          font-family: inherit;
          border-radius: 2.3rem;
          text-shadow: (0px 0px 2px rgba(0, 0, 0, 0.229));
          box-shadow: 3px 3px 13px -2px rgba(0, 0, 0, 0.5);

          &.learn-more {
            width: 29.5rem;
            height: auto;
            .circle {
              @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
              position: relative;
              display: block;
              margin: 0;
              width: 5rem;
              height: 5rem;
              background: $main-color;
              border-radius: 2.1rem;
              .icon {
                @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                
                &.arrow {
                  @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
                  left: 2.9rem;
                  height: 0.6rem;
                  background: none;
                  &::before {
                    position: absolute;
                    content: '';
                    top: -0.25rem;
                    right: 0.0625rem;
                    width: 1rem;
                    height: 1rem;
                    border-top: 0.125rem solid #fff;
                    border-right: 0.125rem solid #fff;
                    transform: rotate(45deg);
                  }
                }
              }
            }
            .button-text {
              @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
              position: absolute;
              top: 15px;
              left: 15px;
              right: 0;
              bottom: 0;
              padding: 0.75rem 0;
              margin: 0 0 0 1.5rem;
              color: black;
              font-weight: 700;
              text-align: center;
              text-transform: uppercase;
              font-size: 1.3rem;
            }
          }
          &:hover {
            .circle {
              width: 100%;
              .icon {
                &.arrow {
                
                transform: translate(1rem, 0);
                &::before {
                    position: absolute;
                    content: '';
                    top: -0.25rem;
                    right: 0.0625rem;
                    width: 1rem;
                    height: 1rem;
                    border-top: 0.125rem solid $secondary-color;
                    border-right: 0.125rem solid $secondary-color;
                    transform: rotate(45deg);
                  }
                }
              }
            }
            .button-text {
              color: white;
            }
          }
      }
  }
  position: relative;

  margin: 20px auto;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  .rekrutacja1-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .rekrutacja1-item {
      position: relative;
      margin-inline: 20px;
      .item-text {
        inset: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 3;
        color: #ffffff;
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
        justify-content: center;
        background-color: rgba(128, 99, 72, 0.699);
        text-shadow: (0px 3px 5px rgba(0, 0, 0, 0.529));
        h2{
            margin: 20px 10px;
        }
        p{
            margin-inline: 10px;
        }
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }
  
}
.rekrutacja2{
    background-color: #1a61362d;
    padding:20px 0;
    .rekrutacja2-container{
        max-width: 1000px;
        margin:20px auto;
        .rekrutacja2-text1{
            margin: 20px;
            background-color: $main-color;
            color: white;
            padding: 20px;
            border-radius: 10px;
            text-align: center;
            
            
        }
        .rekrutacja2-text2{
            margin: 20px;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            h3{
                margin-bottom: 10px ;
            }
            i{
                color: #004b1e;
            }
        }
        .rekrutacja2-text3{
            margin: 20px;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
           
        }
        .rekrutacja2-text4{
            margin: 20px;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            i{
                color: #004b1e;
            }
            h2{
                margin-bottom: 10px ;
            }
        }
        .rekrutacja2-text5{
            margin: 20px;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            h2{
                margin-bottom: 10px ;
            }
            h3{
                margin: 20px 0;
                color: #004b1e;
                text-align: center;
                font-size: 50px;
            }
            span{
                font-size: 26px;
                color: #006127;
                font-weight: 500;
            }
        }
    }
}

@media only screen and (max-width: 1130px) {
  .rekrutacja1{
    .rekrutacja1-container{
      flex-direction: column;
      gap: 10px;
    }
    .karta-button{
      margin: 20px auto;
      button{
        
        &.learn-more{
          width: 22.5rem;
          .circle {
            width: 4.5rem;
              height: 4.5rem;
              border-radius:2.2rem;
              .icon {
                &.arrow {
                  &::before {
                    right: 0.19rem;
                    border-top: 0.125rem solid $secondary-color;
                    border-right: 0.125rem solid $secondary-color;
                  }
                }
              }
              
          }
          .button-text{
            font-size: 1.08rem;
            left: 40px;
          }
        }
        &:hover{
          .circle {
            .icon {
              &.arrow {
                transform: translate(0, 0);
              }
            }
          }
          .button-text {
            color: black;
          }
        }
      }
    }
    .karta-text{
      text-align: center;
    }
  }
  .rekrutacja2{
    .rekrutacja2-container{
      .rekrutacja2-text2{
        margin: 10px;
      }
      .rekrutacja2-text3{
        margin: 10px;
      }
      .rekrutacja2-text4{
        margin: 10px;
      }
      .rekrutacja2-text5{
        margin: 10px;
        h3{
          font-size: 40px;
        }
      }
    }
  }
}