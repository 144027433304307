.rekrutacja1 {
  position: relative;
  margin: 20px auto;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
}
.rekrutacja1 .karta-text {
  text-align: left;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 0;
}
.rekrutacja1 .karta-text h3 {
  margin-inline: 20px;
}
.rekrutacja1 .karta-text h3 span {
  color: #004b1e;
  text-decoration: underline;
}
.rekrutacja1 .karta-text .start__free,
.rekrutacja1 .karta-text .start__free:after {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all linear 0.2s;
}
.rekrutacja1 .karta-text .start__free {
  color: #ff564f;
  padding-left: 8px;
  position: relative;
  font-size: 26px;
}
.rekrutacja1 .karta-text a,
.rekrutacja1 .karta-text a:focus,
.rekrutacja1 .karta-text a:hover {
  text-decoration: none;
  outline: 0;
}
.rekrutacja1 .karta-text .start__free:after {
  position: absolute;
  content: "";
  width: 37%;
  height: 3px;
  left: 4%;
  background: #ff564f;
  bottom: -8px;
}
.rekrutacja1 .karta-text .start__free:hover:after {
  width: 100%;
  left: 2%;
}
.rekrutacja1 .karta-text .start__free:hover {
  letter-spacing: 1.2px;
}
.rekrutacja1 .karta-button {
  margin: 40px auto;
}
.rekrutacja1 .karta-button button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: rgba(26, 97, 54, 0.1764705882);
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  border-radius: 2.3rem;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.229);
  box-shadow: 3px 3px 13px -2px rgba(0, 0, 0, 0.5);
}
.rekrutacja1 .karta-button button.learn-more {
  width: 29.5rem;
  height: auto;
}
.rekrutacja1 .karta-button button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 5rem;
  height: 5rem;
  background: #1a6136;
  border-radius: 2.1rem;
}
.rekrutacja1 .karta-button button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.rekrutacja1 .karta-button button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 2.9rem;
  height: 0.6rem;
  background: none;
}
.rekrutacja1 .karta-button button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 1rem;
  height: 1rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
.rekrutacja1 .karta-button button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 15px;
  left: 15px;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.5rem;
  color: black;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.3rem;
}
.rekrutacja1 .karta-button button:hover .circle {
  width: 100%;
}
.rekrutacja1 .karta-button button:hover .circle .icon.arrow {
  transform: translate(1rem, 0);
}
.rekrutacja1 .karta-button button:hover .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 1rem;
  height: 1rem;
  border-top: 0.125rem solid #ffad33;
  border-right: 0.125rem solid #ffad33;
  transform: rotate(45deg);
}
.rekrutacja1 .karta-button button:hover .button-text {
  color: white;
}
.rekrutacja1 .rekrutacja1-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.rekrutacja1 .rekrutacja1-container .rekrutacja1-item {
  position: relative;
  margin-inline: 20px;
}
.rekrutacja1 .rekrutacja1-container .rekrutacja1-item .item-text {
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 3;
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  justify-content: center;
  background-color: rgba(128, 99, 72, 0.699);
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.529);
}
.rekrutacja1 .rekrutacja1-container .rekrutacja1-item .item-text h2 {
  margin: 20px 10px;
}
.rekrutacja1 .rekrutacja1-container .rekrutacja1-item .item-text p {
  margin-inline: 10px;
}
.rekrutacja1 .rekrutacja1-container .rekrutacja1-item img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}

.rekrutacja2 {
  background-color: rgba(26, 97, 54, 0.1764705882);
  padding: 20px 0;
}
.rekrutacja2 .rekrutacja2-container {
  max-width: 1000px;
  margin: 20px auto;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text1 {
  margin: 20px;
  background-color: #1a6136;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text2 {
  margin: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text2 h3 {
  margin-bottom: 10px;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text2 i {
  color: #004b1e;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text3 {
  margin: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text4 {
  margin: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text4 i {
  color: #004b1e;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text4 h2 {
  margin-bottom: 10px;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text5 {
  margin: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text5 h2 {
  margin-bottom: 10px;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text5 h3 {
  margin: 20px 0;
  color: #004b1e;
  text-align: center;
  font-size: 50px;
}
.rekrutacja2 .rekrutacja2-container .rekrutacja2-text5 span {
  font-size: 26px;
  color: #006127;
  font-weight: 500;
}

@media only screen and (max-width: 1130px) {
  .rekrutacja1 .rekrutacja1-container {
    flex-direction: column;
    gap: 10px;
  }
  .rekrutacja1 .karta-button {
    margin: 20px auto;
  }
  .rekrutacja1 .karta-button button.learn-more {
    width: 22.5rem;
  }
  .rekrutacja1 .karta-button button.learn-more .circle {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 2.2rem;
  }
  .rekrutacja1 .karta-button button.learn-more .circle .icon.arrow::before {
    right: 0.19rem;
    border-top: 0.125rem solid #ffad33;
    border-right: 0.125rem solid #ffad33;
  }
  .rekrutacja1 .karta-button button.learn-more .button-text {
    font-size: 1.08rem;
    left: 40px;
  }
  .rekrutacja1 .karta-button button:hover .circle .icon.arrow {
    transform: translate(0, 0);
  }
  .rekrutacja1 .karta-button button:hover .button-text {
    color: black;
  }
  .rekrutacja1 .karta-text {
    text-align: center;
  }
  .rekrutacja2 .rekrutacja2-container .rekrutacja2-text2 {
    margin: 10px;
  }
  .rekrutacja2 .rekrutacja2-container .rekrutacja2-text3 {
    margin: 10px;
  }
  .rekrutacja2 .rekrutacja2-container .rekrutacja2-text4 {
    margin: 10px;
  }
  .rekrutacja2 .rekrutacja2-container .rekrutacja2-text5 {
    margin: 10px;
  }
  .rekrutacja2 .rekrutacja2-container .rekrutacja2-text5 h3 {
    font-size: 40px;
  }
}/*# sourceMappingURL=rekrutacja.css.map */